const React = require('react');
const { string, shape } = require('prop-types');

const { Pill } = require('@andes/badge');
const classnames = require('classnames');

const namespace = 'ui-search-listing-disclaimer';
const ListingDisclaimer = ({ text, currentLayout }) => (
  <div className={classnames(`${namespace} ${namespace}--${currentLayout.id}`, 'shops__listing-disclaimer')}>
    <div
      className={classnames(
        `${namespace}__card ${namespace}__card--${currentLayout.id}`,
        'shops__listing-disclaimer-card',
      )}
    >
      <Pill
        className={classnames(`${namespace}__icon`, 'shops__listing-disclaimer-icon')}
        size="small"
        color="accent"
        hierarchy="loud"
        contentType="icon"
      />
      <p className={classnames(`${namespace}__text`, 'shops-custom-secondary-font')}>{text}</p>
    </div>
  </div>
);

ListingDisclaimer.propTypes = {
  currentLayout: shape({}).isRequired,
  text: string.isRequired,
};

module.exports = ListingDisclaimer;
